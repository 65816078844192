// src/utils/iconUtils.js

import iconFileNames from "../iconFileNames.json";

export const getIconFileName = (seasonName) => {
        function preprocess(str) {
                return str
                        .toLowerCase()
                        .replace(/[^\w\s]/g, "") // Remove punctuation
                        .replace(/\d+/g, "") // Remove numbers
                        .trim()
                        .split(/\s+/);
        }

        const seasonWords = preprocess(seasonName);
        let maxCommonWords = 0;
        let bestMatch = "iRacing-Icon-BW-White"; // Default icon
        let minExtraWords = Number.MAX_SAFE_INTEGER;

        iconFileNames.forEach((iconName) => {
                const iconWords = preprocess(iconName);
                const commonWords = iconWords.filter((word) => seasonWords.includes(word));
                if (commonWords.length > maxCommonWords) {
                        maxCommonWords = commonWords.length;
                        bestMatch = iconName;
                        minExtraWords = iconWords.length - commonWords.length;
                } else if (commonWords.length === maxCommonWords) {
                        const extraWords = iconWords.length - commonWords.length;
                        if (extraWords < minExtraWords) {
                                bestMatch = iconName;
                                minExtraWords = extraWords;
                        }
                }
        });

        return bestMatch;
};
