import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { getIconFileName } from "../utils/iconUtils";
import "../styles/RaceResultsPage.css";
import RecentSessionsList from "./RecentSessionsList";
import Plot from "react-plotly.js";

const RaceResultsPage = () => {
        const [raceData, setRaceData] = useState(null);
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState(null);
        const subsessionParam = useParams();
        const [recentSessions, setRecentSessions] = useState(null);
        const [recentSessionsLoading, setRecentSessionsLoading] = useState(true);
        const [percentilesLoading, setPercentilesLoading] = useState(true);
        const [percentiles, setPercentiles] = useState(null);
        const [percentilesQuali, setPercentilesQuali] = useState(null);
        const [searchId, setSearchId] = useState("");

        useEffect(() => {
                // Function to fetch race data
                const fetchRaceData = async () => {
                        setIsLoading(true);
                        try {
                                const response = await fetch(`https://iracing6-backend.herokuapp.com/api/sessionData/results/${subsessionParam.subsession_id}`);
                                if (!response.ok) {
                                        throw new Error("Network response was not ok");
                                }
                                const data = await response.json();
                                setRaceData(data);
                                // Additional fetches or logic here...
                                fetchPercentiles(data.season_name, "QUALIFY");
                                fetchPercentiles(data.season_name, "RACE");
                        } catch (error) {
                                console.error("Error fetching data:", error);
                                setError("no subsession ID found");
                        } finally {
                                setIsLoading(false);
                        }
                };

                // Call the fetch function if subsessionParam.subsession_id is available
                if (subsessionParam.subsession_id) {
                        fetchRaceData();
                } else {
                        setIsLoading(false);
                }
        }, [subsessionParam.subsession_id]);

        // pull percentiles from backend

        const fetchPercentiles = async (season, type) => {
                try {
                        const response = await axios.get(`https://iracing6-backend.herokuapp.com/api/series-car-percentiles/?seasonName=${season}&sessionType=${type}`);
                        if (type === "RACE") {
                                setPercentiles(response.data);
                        }
                        if (type === "QUALIFY") {
                                setPercentilesQuali(response.data);
                        }
                } catch (error) {
                        console.error("Error fetching recent sessions:", error);
                } finally {
                        setPercentilesLoading(false);
                }
        };

        const calculatePercentile = (value, carClass, sessionType) => {
                if (percentiles && sessionType === "RACE") {
                        // make sure carClass matches percentiles.car_class_name
                        let carClassIndex = percentiles[0].car_class_name;
                        if (carClassIndex !== carClass) {
                                return "error";
                        }

                        // make sure that sessionType matches percentiles.session_type
                        let sessionTypeIndex = percentiles[0].session_type;
                        if (sessionTypeIndex !== sessionType) {
                                return "error";
                        }

                        // find the percentiles.percentiles[] value that is closest to value
                        let closestPercentile = 0;
                        let closestPercentileIndex = 0;
                        let closestPercentileValue = 0;
                        for (let i = 0; i < percentiles[0].percentiles.length; i++) {
                                let currentPercentile = percentiles[0].percentiles[i];
                                if (Math.abs(currentPercentile - value) < Math.abs(closestPercentile - value)) {
                                        closestPercentile = currentPercentile;
                                        closestPercentileIndex = i;
                                        closestPercentileValue = currentPercentile;
                                }
                        }
                        return closestPercentileIndex;
                }
                if (percentilesQuali && sessionType === "QUALIFY") {
                        // make sure carClass matches percentiles.car_class_name
                        let carClassIndex = percentilesQuali[0].car_class_name;
                        if (carClassIndex !== carClass) {
                                return "error";
                        }

                        // make sure that sessionType matches percentiles.session_type
                        let sessionTypeIndex = percentilesQuali[0].session_type;
                        if (sessionTypeIndex !== sessionType) {
                                return "error";
                        }

                        // find the percentiles.percentiles[] value that is closest to value
                        let closestPercentile = 0;
                        let closestPercentileIndex = 0;
                        let closestPercentileValue = 0;
                        for (let i = 0; i < percentilesQuali[0].percentiles.length; i++) {
                                let currentPercentile = percentilesQuali[0].percentiles[i];
                                if (Math.abs(currentPercentile - value) < Math.abs(closestPercentile - value)) {
                                        closestPercentile = currentPercentile;
                                        closestPercentileIndex = i;
                                        closestPercentileValue = currentPercentile;
                                }
                        }
                        return closestPercentileIndex;
                }
        };

        const calculateLeaderboardPosition = (value, carClass, sessionType) => {
                if (sessionType === "RACE") {
                        if (percentiles) {
                                // make sure carClass matches percentiles.car_class_name
                                let carClassIndex = percentiles[0].car_class_name;
                                if (carClassIndex !== carClass) {
                                        return "error";
                                }

                                // calculate the closest percentiles[0].leaderboard to value, and return the index
                                let closestLeaderboard = 0;
                                let closestLeaderboardIndex = 0;
                                let closestLeaderboardValue = 0;
                                for (let i = 0; i < percentiles[0].leaderboard.length; i++) {
                                        let currentLeaderboard = percentiles[0].leaderboard[i];
                                        if (Math.abs(currentLeaderboard - value) < Math.abs(closestLeaderboard - value)) {
                                                closestLeaderboard = currentLeaderboard;
                                                closestLeaderboardIndex = i;
                                                closestLeaderboardValue = currentLeaderboard;
                                        }
                                }
                                return closestLeaderboardIndex + 1;
                        }
                }
                if (sessionType === "QUALIFY") {
                        if (percentilesQuali) {
                                // calculate the closest percentiles[0].leaderboard to value, and return the index
                                let closestLeaderboard = 0;
                                let closestLeaderboardIndex = 0;
                                let closestLeaderboardValue = 0;
                                for (let i = 0; i < percentilesQuali[0].leaderboard.length; i++) {
                                        let currentLeaderboard = percentilesQuali[0].leaderboard[i];
                                        if (Math.abs(currentLeaderboard - value) < Math.abs(closestLeaderboard - value)) {
                                                closestLeaderboard = currentLeaderboard;
                                                closestLeaderboardIndex = i;
                                                closestLeaderboardValue = currentLeaderboard;
                                        }
                                }
                                return closestLeaderboardIndex + 1;
                        }
                }
        };

        useEffect(() => {
                const fetchRecentSessions = async () => {
                        // todo: avoid if already on the results page
                        try {
                                const response = await axios.get(`https://iracing6-backend.herokuapp.com/api/sessionData/resultsrecent?limit=200`);
                                setRecentSessions(response.data);
                        } catch (error) {
                                console.error("Error fetching recent sessions:", error);
                        } finally {
                                setRecentSessionsLoading(false);
                        }
                };
                fetchRecentSessions();
        }, []);

        const navigate = useNavigate();

        const handleNameLink = (id) => {
                navigate(`/user/careerstats/${id}`);
        };

        // Process the sessions data into a unique structure
        const uniqueSessionData = {};

        if (recentSessions) {
                recentSessions.forEach((session) => {
                        const key = `${session.start_time} - ${session.season_name}`;
                        if (!uniqueSessionData[key]) {
                                uniqueSessionData[key] = [];
                        }
                        uniqueSessionData[key].push(session);
                });
        }

        const formatTimeSince = (timeDiff) => {
                // Convert timeDiff from milliseconds to minutes, hours, days, etc.
                // and format it as a string like "2 hours ago", "3 days ago"
                // This is a basic example, you might want to handle different cases
                let minutes = Math.floor(timeDiff / 60000);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);

                if (days > 0) {
                        return `Started: ${days} day(s) ago`;
                } else if (hours > 0) {
                        return `Started: ${hours} hour(s) ago`;
                } else {
                        return `Started: ${minutes} minutes ago`;
                }
        };

        if (isLoading) {
                return <div>Loading...</div>;
        }

        if (error) {
                if (error === "no subsession ID found") {
                        return <RecentSessionsList setSearchId={setSearchId} searchId={searchId} />;
                } else {
                        return <div>Error loading data.</div>;
                }
        }

        console.log(raceData);

        if (raceData) {
                function getLicenseLevelLetter(level) {
                        if (level >= 15 && level <= 20) {
                                return "A";
                        } else if (level >= 10 && level <= 14) {
                                return "B";
                        } else if (level >= 5 && level <= 9) {
                                return "C";
                        } else if (level >= 1 && level <= 4) {
                                return "D";
                        } else {
                                return "Unknown"; // For any unexpected value
                        }
                }

                // loop through all of the best lap numbers and push them to an array
                let bestLapNums = [];
                raceData.results[2].results.map((element, index) => {
                        if (element.best_lap_num !== -1) {
                                bestLapNums.push(element.best_lap_num);
                        }
                });

                // do the same for incidents and push them to an array
                let incidentsNums = [];
                raceData.results[2].results.map((element, index) => {
                        if (element.incidents !== -1) {
                                incidentsNums.push(element.incidents);
                        }
                });

                const numberOfLapsInTheRace = raceData.results[2].results[0].laps_complete;

                const generateLapNumberChart = (lapNumbers) => {
                        const lapNumberCounts = lapNumbers.reduce((acc, lap) => {
                                acc[lap] = (acc[lap] || 0) + 1;
                                return acc;
                        }, {});

                        const lapLabels = Object.keys(lapNumberCounts);
                        const lapValues = Object.values(lapNumberCounts);

                        return (
                                <Plot
                                        data={[
                                                {
                                                        x: lapLabels,
                                                        y: lapValues,
                                                        type: "bar",
                                                        marker: {
                                                                color: "#1e53a3dc",
                                                                line: {
                                                                        color: "#6e788a",
                                                                        width: 1,
                                                                },
                                                        },
                                                },
                                        ]}
                                        layout={{
                                                margin: { t: 0, r: 0, l: 35, b: 35 },
                                                width: 400,
                                                height: 200,
                                                xaxis: {
                                                        title: { text: "Lap #", font: { size: window.innerWidth < 800 ? 10 : 12 } },
                                                        color: "rgba(200, 200, 200, 1)",
                                                        family: "Questrial, sans-serif",
                                                        range: [0.5, numberOfLapsInTheRace + 0.5],
                                                        tick0: 1,

                                                        gridcolor: "rgba(80, 80, 80, 1)",
                                                        tickfont: {
                                                                color: "rgba(200, 200, 200, 1)",
                                                                size: window.innerWidth < 800 ? 10 : 10,
                                                        },
                                                },
                                                yaxis: {
                                                        gridcolor: "rgba(80, 80, 80, 1)",
                                                        tickfont: {
                                                                color: "rgba(200, 200, 200, 1)",
                                                                family: "Questrial, sans-serif",
                                                        },
                                                        title: { text: "# of Drivers", font: { size: window.innerWidth < 800 ? 10 : 12 } },
                                                        color: "rgba(200, 200, 200, 1)",
                                                        family: "Questrial, sans-serif",
                                                },
                                                plot_bgcolor: "#29272b",
                                                paper_bgcolor: "#29272b",
                                        }}
                                        config={{ displayModeBar: false }}
                                />
                        );
                };

                const generateIncidentsChart = (incidents) => {
                        const incidentCounts = incidents.reduce((acc, incident) => {
                                acc[incident] = (acc[incident] || 0) + 1;
                                return acc;
                        }, {});

                        const incidentLabels = Object.keys(incidentCounts);
                        const incidentValues = Object.values(incidentCounts);

                        return (
                                <Plot
                                        data={[
                                                {
                                                        x: incidentLabels,
                                                        y: incidentValues,
                                                        type: "bar",
                                                        marker: {
                                                                color: "#1e53a3dc",
                                                                line: {
                                                                        color: "#6e788a",
                                                                        width: 1,
                                                                },
                                                        },
                                                },
                                        ]}
                                        layout={{
                                                margin: { t: 0, r: 0, l: 35, b: 35 },
                                                width: 400,
                                                height: 200,
                                                xaxis: {
                                                        title: { text: "Incident Points", font: { size: window.innerWidth < 800 ? 10 : 12 } },
                                                        color: "rgba(200, 200, 200, 1)",
                                                        family: "Questrial, sans-serif",
                                                        range: [0.5, Math.max(...incidents) + 0.5],
                                                        tick0: 1,
                                                        gridcolor: "rgba(80, 80, 80, 1)",
                                                        tickfont: {
                                                                color: "rgba(200, 200, 200, 1)",
                                                                size: window.innerWidth < 800 ? 10 : 10,
                                                        },
                                                },
                                                yaxis: {
                                                        gridcolor: "rgba(80, 80, 80, 1)",
                                                        tickfont: {
                                                                color: "rgba(200, 200, 200, 1)",
                                                                family: "Questrial, sans-serif",
                                                        },
                                                        title: { text: "# of Drivers", font: { size: window.innerWidth < 800 ? 10 : 12 } },
                                                        color: "rgba(200, 200, 200, 1)",
                                                        family: "Questrial, sans-serif",
                                                },
                                                plot_bgcolor: "#29272b",
                                                paper_bgcolor: "#29272b",
                                        }}
                                        config={{ displayModeBar: false }}
                                />
                        );
                };

                return (
                        <div className="race-results-cont">
                                <div className="rr-sesh-info-cont">
                                        <div className="rr-sesh-info-title">{raceData.season_name}</div>
                                        <div className="rr-sesh-info-item">
                                                <h1>Subsession ID: </h1>
                                                {subsessionParam.subsession_id}
                                        </div>
                                        <div className="rr-sesh-info-item">
                                                <h1>Strength of Field: </h1>
                                                {raceData.event_strength_of_field}
                                        </div>
                                        <div className="rr-sesh-info-item">
                                                <h1>Start Time: </h1>
                                                {raceData.start_time}
                                        </div>
                                        <div className="rr-sesh-info-item">
                                                <h1>Track: </h1>
                                                {raceData.track_name}
                                        </div>
                                </div>
                                <div className="rr-top-graph-row-container">
                                        <div className="rr-top-graph-cont">
                                                <div className="rr-lap-chart-title-div">
                                                        <div className="rr-lap-chart-title">Best Lap Numbers:</div>
                                                </div>
                                                <div>{generateLapNumberChart(bestLapNums)}</div>
                                        </div>
                                        <div className="rr-top-graph-cont">
                                                <div className="rr-lap-chart-title-div">
                                                        <div className="rr-lap-chart-title">Incidents:</div>
                                                </div>
                                                <div>{generateIncidentsChart(incidentsNums)}</div>
                                        </div>
                                </div>

                                <div className="sesh-centering-div">
                                        <div className="sesh-type-title">RACE</div>
                                </div>
                                <div className="race-result-table-row-headers-cont">
                                        <div className="race-result-table-row-headers-block-pos">Pos</div>
                                        <div className="race-result-table-row-headers-block-driver">Driver</div>
                                        <div className="race-result-table-row-headers-block-irsr">ir/sr</div>
                                        <div className="race-result-table-row-headers-block-car">Car</div>
                                        <div className="race-result-table-row-headers-block-fl">Fastest Lap / Week %</div>
                                </div>
                                {raceData.results.map((element, index) =>
                                        element.simsession_name === "RACE"
                                                ? element.results.map((result, index) =>
                                                          // hide the results with fastest lap times of -1
                                                          result.best_lap_time !== -1 ? (
                                                                  <div key={index} className="race-result-table-row-cont">
                                                                          <div className="race-result-table-row-block-pos">{result.finish_position + 1}</div>
                                                                          <div className="race-result-table-row-block-name" onClick={() => handleNameLink(result.cust_id)}>
                                                                                  {result.display_name}
                                                                          </div>
                                                                          <div className="race-result-table-row-block-irsr">
                                                                                  <div className="rrir">
                                                                                          {result.newi_rating}
                                                                                          {result.oldi_rating > result.newi_rating ? (
                                                                                                  <p>-{result.oldi_rating - result.newi_rating}</p>
                                                                                          ) : (
                                                                                                  <p>+{result.newi_rating - result.oldi_rating}</p>
                                                                                          )}
                                                                                  </div>
                                                                                  <div className="rrsr">
                                                                                          {getLicenseLevelLetter(result.new_license_level)}{" "}
                                                                                          {result.new_sub_level.toString().length > 1
                                                                                                  ? result.new_sub_level.toString().slice(0, 1) + "." + result.new_sub_level.toString().slice(1)
                                                                                                  : result.new_sub_level}
                                                                                          {result.old_sub_level > result.new_sub_level ? (
                                                                                                  <p>-{(result.old_sub_level - result.new_sub_level) / 100}</p>
                                                                                          ) : (
                                                                                                  <p>+{(result.new_sub_level - result.old_sub_level) / 100}</p>
                                                                                          )}
                                                                                  </div>
                                                                          </div>

                                                                          <div className="race-result-table-row-block-car">{result.car_name.split(" ").slice(0, 4).join(" ")}</div>
                                                                          <div className="race-result-table-row-block-fl">
                                                                                  {Math.floor(result.best_lap_time / 10000 / 60) +
                                                                                          ":" +
                                                                                          ("0" + Math.floor((result.best_lap_time / 10000) % 60)).slice(-2) +
                                                                                          ":" +
                                                                                          ("000" + (result.best_lap_time % 10000)).slice(-4)}
                                                                          </div>
                                                                          <div className="race-result-table-row-block-equals">=</div>
                                                                          <div
                                                                                  className={`race-result-table-row-block-perc ${
                                                                                          calculatePercentile(result.best_lap_time, result.car_class_name, "RACE") === 0
                                                                                                  ? "percentile-top-0"
                                                                                                  : calculatePercentile(result.best_lap_time, result.car_class_name, "RACE") <= 5
                                                                                                  ? "percentile-top-5"
                                                                                                  : ""
                                                                                  }`}
                                                                          >
                                                                                  {calculatePercentile(result.best_lap_time, result.car_class_name, "RACE") === 0
                                                                                          ? "Week Rank " + "#" + calculateLeaderboardPosition(result.best_lap_time, result.car_class_name, "RACE")
                                                                                          : `Top ${calculatePercentile(result.best_lap_time, result.car_class_name, "RACE")}%`}
                                                                          </div>
                                                                  </div>
                                                          ) : null
                                                  )
                                                : null
                                )}
                                <div className="sesh-centering-div">
                                        <div className="sesh-type-title">QUALIFYING</div>
                                </div>
                                {raceData.results.map((element, index) =>
                                        element.simsession_name === "QUALIFY"
                                                ? element.results.map((result, index) =>
                                                          // hide the results with fastest lap times of -1
                                                          result.best_lap_time !== -1 ? (
                                                                  <div key={index} className="race-result-table-row-cont">
                                                                          <div className="race-result-table-row-block-pos">{result.finish_position + 1}</div>
                                                                          <div className="race-result-table-row-block-name" onClick={() => handleNameLink(result.cust_id)}>
                                                                                  {result.display_name}
                                                                          </div>
                                                                          <div className="race-result-table-row-block-irsr">
                                                                                  <div className="rrir">
                                                                                          {result.newi_rating}
                                                                                          {result.oldi_rating > result.newi_rating ? (
                                                                                                  <p>-{result.oldi_rating - result.newi_rating}</p>
                                                                                          ) : (
                                                                                                  <p>+{result.newi_rating - result.oldi_rating}</p>
                                                                                          )}
                                                                                  </div>
                                                                                  <div className="rrsr">
                                                                                          {getLicenseLevelLetter(result.new_license_level)}{" "}
                                                                                          {result.new_sub_level.toString().length > 1
                                                                                                  ? result.new_sub_level.toString().slice(0, 1) + "." + result.new_sub_level.toString().slice(1)
                                                                                                  : result.new_sub_level}
                                                                                          {result.old_sub_level > result.new_sub_level ? (
                                                                                                  <p>-{(result.old_sub_level - result.new_sub_level) / 100}</p>
                                                                                          ) : (
                                                                                                  <p>+{(result.new_sub_level - result.old_sub_level) / 100}</p>
                                                                                          )}
                                                                                  </div>
                                                                          </div>

                                                                          <div className="race-result-table-row-block-car">{result.car_name}</div>
                                                                          <div className="race-result-table-row-block-fl">
                                                                                  {Math.floor(result.best_lap_time / 10000 / 60) +
                                                                                          ":" +
                                                                                          ("0" + Math.floor((result.best_lap_time / 10000) % 60)).slice(-2) +
                                                                                          ":" +
                                                                                          ("000" + (result.best_lap_time % 10000)).slice(-4)}
                                                                          </div>
                                                                          <div className="race-result-table-row-block-equals">=</div>
                                                                          <div
                                                                                  className={`race-result-table-row-block-perc ${
                                                                                          calculatePercentile(result.best_lap_time, result.car_class_name, "QUALIFY") === 0
                                                                                                  ? "percentile-top-0"
                                                                                                  : calculatePercentile(result.best_lap_time, result.car_class_name, "QUALIFY") <= 5
                                                                                                  ? "percentile-top-5"
                                                                                                  : ""
                                                                                  }`}
                                                                          >
                                                                                  {calculatePercentile(result.best_lap_time, result.car_class_name, "QUALIFY") === 0
                                                                                          ? "Week Rank " + "#" + calculateLeaderboardPosition(result.best_lap_time, result.car_class_name, "QUALIFY")
                                                                                          : `Top ${calculatePercentile(result.best_lap_time, result.car_class_name, "QUALIFY")}%`}
                                                                          </div>
                                                                  </div>
                                                          ) : null
                                                  )
                                                : null
                                )}
                        </div>
                );
        } else {
                return <div>No subsession ID found in the URL.</div>;
        }
};

export default RaceResultsPage;
