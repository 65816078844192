import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import "../styles/SeasonSummaryMulti.scss";
import { carColors } from "../utils/carColors";

export const SeasonSummaryMulti = () => {
        const [isLoading, setIsLoading] = useState(true);
        const [carData, setCarData] = useState([]);

        useEffect(() => {
                const fetchData = async () => {
                        setIsLoading(true);
                        const response = await fetch("https://iracing6-backend.herokuapp.com/api/times-by-car/");
                        const jsonData = await response.json();
                        setCarData(jsonData);
                        setIsLoading(false);
                };
                fetchData();
        }, []);

        const seasonCarCounts = carData.reduce((counts, car) => {
                counts[car.season_name] = counts[car.season_name] || {};
                counts[car.season_name][car.car_name] = (counts[car.season_name][car.car_name] || 0) + car.race_best.length;
                return counts;
        }, {});

        //removing Season 4 data for now
        const filteredSeasonCarCounts = Object.entries(seasonCarCounts).filter(([season, cars]) => season !== "GT Sprint VRS Series - 2023 Season 4");

        const sortedSeasons = filteredSeasonCarCounts
                .map(([season, cars]) => {
                        const sortedCars = Object.entries(cars).sort((a, b) => b[1] - a[1]);
                        return [season, sortedCars];
                })
                .sort((a, b) => b[1][0][1] - a[1][0][1]);

        if (isLoading) {
                return (
                        <div>
                                <div className="spinner"></div>
                                <div className="loading-text-div-bpc">Loading data...</div>
                        </div>
                );
        } else {
                return (
                        <div>
                                <div className="ssm-title-div">2023 season 4</div>
                                <div className="season-summary-graph-container">
                                        {sortedSeasons.map(([season, cars], index) => (
                                                <div key={index}>
                                                        <Plot
                                                                data={[
                                                                        {
                                                                                type: "pie",
                                                                                labels: cars.map(([car, count]) => `${car} (${count})`), // Modified this line
                                                                                values: cars.map(([_, count]) => count),
                                                                                opacity: 0.8,
                                                                                marker: {
                                                                                        colors: cars.map(([car, _]) => carColors[car] || "grey"),
                                                                                        line: {
                                                                                                color: "rgba(255, 255, 255, 0.3)", // Outline color
                                                                                                width: 2, // Outline width
                                                                                                radius: 70, // Outline radius, percentage of the pie radius
                                                                                        },
                                                                                },
                                                                                textfont: {
                                                                                        color: "white",
                                                                                },
                                                                        },
                                                                ]}
                                                                layout={{
                                                                        legend: {
                                                                                font: {
                                                                                        color: "rgba(200, 200, 200, 1)", // Replace with your desired color
                                                                                },
                                                                                orientation: window.innerWidth < 800 ? "h" : "v",
                                                                                x: window.innerWidth < 800 ? 0.1 : {}, // this seems to adjust the width of chart on mobile
                                                                                y: window.innerWidth < 800 ? -0.4 : {},
                                                                                // xanchor: "center",
                                                                                // yanchor: "top",
                                                                        },
                                                                        title: `${season}`,

                                                                        plot_bgcolor: "#1e1e1f",
                                                                        paper_bgcolor: "#1e1e1f",
                                                                        font: {
                                                                                color: "rgba(200, 200, 200, 1)",
                                                                        },
                                                                        width: window.innerWidth > 800 ? 800 : window.innerWidth,
                                                                        height: window.innerWidth > 800 ? 600 : window.innerWidth,
                                                                        margin: window.innerWidth > 800 ? { l: 40, r: 40, t: 60, b: 60 } : { l: 0, r: 0, t: 90, b: 240 },
                                                                }}
                                                        />
                                                </div>
                                        ))}
                                </div>
                        </div>
                );
        }
};
