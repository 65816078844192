// WorldRecordsPage.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/WorldRecordsPage.css";

export const WorldRecordsPage = ({ setIsLoggedIn }) => {
        const [listOfCars, setListOfCars] = useState([]);
        const [listOfTracks, setListOfTracks] = useState([]);
        const [searchTerm, setSearchTerm] = useState("");
        const [trackSearchTerm, setTrackSearchTerm] = useState("");
        const [selectedCar, setSelectedCar] = useState(null);
        const [selectedTrack, setSelectedTrack] = useState(null);
        const navigate = useNavigate();

        useEffect(() => {
                const fetchCars = async () => {
                        const response = await fetch(`https://iracing6-backend.herokuapp.com/api/car-basic-info/`);
                        const data = await response.json();
                        if (response.ok) {
                                const sortedCars = data.sort((a, b) => a.car_name.localeCompare(b.car_name));
                                setListOfCars(sortedCars);
                        } else {
                                console.error("Failed to fetch cars");
                        }
                };

                const fetchTracks = async () => {
                        const response = await fetch(`https://iracing6-backend.herokuapp.com/api/track-basic-info/`);
                        const data = await response.json();
                        if (response.ok) {
                                const sortedTracks = data.sort((a, b) => a.track_name.localeCompare(b.track_name));
                                setListOfTracks(sortedTracks);
                        } else {
                                console.error("Failed to fetch tracks");
                        }
                };

                fetchCars();
                fetchTracks();
        }, []);

        // Function to generate car list squares
        const generateCarListSquares = () => {
                if (searchTerm.trim() === "") {
                        return null; // Return null if search term is empty
                }

                const filteredCars = listOfCars.filter((car) => car.car_name.toLowerCase().includes(searchTerm.toLowerCase()));

                if (filteredCars.length === 0) {
                        return <div className="wr-no-results">No cars found</div>;
                }

                // Limit the results to 5
                const limitedCars = filteredCars.slice(0, 7);
                const moreThanFive = filteredCars.length > 7;

                return (
                        <>
                                {limitedCars.map((car) => (
                                        <div
                                                key={car.car_id}
                                                className={`wr-selection-square-div ${selectedCar && selectedCar.car_id === car.car_id ? "selected" : ""}`}
                                                onClick={() => {
                                                        setSelectedCar(car);
                                                        setSearchTerm(""); // Clear search input to hide options
                                                }}
                                        >
                                                {car.car_name}
                                        </div>
                                ))}
                                {moreThanFive && <div className="wr-ellipsis">...</div>}
                        </>
                );
        };

        // Function to generate track list squares
        const generateTrackListSquares = () => {
                if (trackSearchTerm.trim() === "") {
                        return null; // Return null if search term is empty
                }

                const filteredTracks = listOfTracks.filter((track) => track.track_name.toLowerCase().includes(trackSearchTerm.toLowerCase()));

                if (filteredTracks.length === 0) {
                        return <div className="wr-no-results">No tracks found</div>;
                }

                // Limit the results to 5
                const limitedTracks = filteredTracks.slice(0, 7);
                const moreThanFive = filteredTracks.length > 7;

                return (
                        <>
                                {limitedTracks.map((track) => (
                                        <div
                                                key={track.track_id}
                                                className={`wr-selection-square-div ${selectedTrack && selectedTrack.track_id === track.track_id ? "selected" : ""}`}
                                                onClick={() => {
                                                        setSelectedTrack(track);
                                                        setTrackSearchTerm(""); // Clear search input to hide options
                                                }}
                                        >
                                                {track.track_name}
                                        </div>
                                ))}
                                {moreThanFive && <div className="wr-ellipsis">...</div>}
                        </>
                );
        };

        // Function to handle the search button click
        const handleSearch = () => {
                if (selectedCar && selectedTrack) {
                        navigate(`/world-records/${selectedCar.car_id}/${selectedTrack.track_id}`, {
                                state: {
                                        carName: selectedCar.car_name,
                                        trackName: selectedTrack.track_name,
                                },
                        });
                } else {
                        alert("Please select both a car and a track");
                }
        };

        return (
                <div className="wr-page-main">
                        {/* Car selection section */}
                        <div className="wr-car-selection-big-containing-div">
                                <input
                                        type="text"
                                        placeholder={selectedCar ? selectedCar.car_name : "Search car..."}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className={`wr-search-bar ${selectedCar ? "full-white-text" : ""}`}
                                />

                                {searchTerm.trim() !== "" && <div className="wr-selection-square-container-div">{generateCarListSquares()}</div>}
                        </div>

                        {/* Track selection section */}
                        <div className="wr-track-selection-big-containing-div">
                                <input
                                        type="text"
                                        placeholder={selectedTrack ? selectedTrack.track_name : "Search track..."}
                                        value={trackSearchTerm}
                                        onChange={(e) => setTrackSearchTerm(e.target.value)}
                                        className={`wr-search-bar ${selectedTrack ? "full-white-text" : ""}`}
                                />

                                {trackSearchTerm.trim() !== "" && <div className="wr-selection-square-container-div">{generateTrackListSquares()}</div>}
                        </div>

                        <div className="wr-selection-go-button" onClick={handleSearch}>
                                Search
                        </div>
                </div>
        );
};
