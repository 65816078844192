// RecentSessions.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getIconFileName } from "../utils/iconUtils";
import "../styles/RaceResultsPage.css";

const RecentSessionsList = ({ setSearchId, searchId }) => {
        const [recentSessions, setRecentSessions] = useState(null);
        const [recentSessionsLoading, setRecentSessionsLoading] = useState(true);

        useEffect(() => {
                const fetchRecentSessions = async () => {
                        try {
                                const response = await axios.get(`https://iracing6-backend.herokuapp.com/api/sessionData/resultsrecent?limit=200`);
                                setRecentSessions(response.data);
                        } catch (error) {
                                console.error("Error fetching recent sessions:", error);
                        } finally {
                                setRecentSessionsLoading(false);
                        }
                };
                fetchRecentSessions();
        }, []);

        const navigate = useNavigate();

        const handleSessionClick = (subsession_id) => {
                window.location.href = `/RaceResults/${subsession_id}`;
        };

        const getIconUrl = (seasonName) => {
                const iconName = getIconFileName(seasonName);
                return `${process.env.PUBLIC_URL}/assets/series-icons/${iconName}.png`;
        };

        const formatTimeSince = (timeDiff) => {
                let minutes = Math.floor(timeDiff / 60000);
                let hours = Math.floor(minutes / 60);
                let days = Math.floor(hours / 24);

                if (days > 0) {
                        return `Started: ${days} day(s) ago`;
                } else if (hours > 0) {
                        return `Started: ${hours} hour(s) ago`;
                } else {
                        return `Started: ${minutes} minutes ago`;
                }
        };

        const uniqueSessionData = {};

        if (recentSessions) {
                recentSessions.forEach((session) => {
                        const key = `${session.start_time} - ${session.season_name}`;
                        if (!uniqueSessionData[key]) {
                                uniqueSessionData[key] = [];
                        }
                        uniqueSessionData[key].push(session);
                });
        }

        const renderSessions = () => {
                if (recentSessionsLoading) {
                        return <div>Loading recent sessions...</div>;
                }
                return Object.entries(uniqueSessionData).map(([key, sessions], index) => {
                        let separatorIndex = key.indexOf(" - ");
                        let firstPart = key.substring(0, separatorIndex);
                        let secondPart = key.substring(separatorIndex + 3);

                        let timestamp = new Date(firstPart);
                        let now = new Date();
                        let timeDiff = now - timestamp;

                        let timeSince = formatTimeSince(timeDiff);

                        return (
                                <div className="centering-div-rr-1" key={index}>
                                        <div className="recent-race-list-cont">
                                                <div className="logo-square-rr">
                                                        <img src={getIconUrl(secondPart)} style={{ maxWidth: "7rem", maxHeight: "5rem" }} alt="icon" />
                                                        <h3>{timeSince}</h3>
                                                </div>
                                                <div className="subsession-side-rr">
                                                        {sessions
                                                                .sort((a, b) => b.event_strength_of_field - a.event_strength_of_field)
                                                                .map((session, index) => (
                                                                        <div key={index} className="subsession-box-rr" onClick={() => handleSessionClick(session.subsession_id)}>
                                                                                <h3>SoF: {session.event_strength_of_field}</h3>
                                                                                <p>ID: {session.subsession_id}</p>
                                                                        </div>
                                                                ))}
                                                </div>
                                        </div>
                                        <div className="recent-race-series-name-row">{sessions[0].season_name}</div>
                                </div>
                        );
                });
        };

        const handleSearchChange = (e) => {
                setSearchId(e.target.value);
        };

        const handleSearchSubmit = () => {
                if (searchId) {
                        navigate(`/RaceResults/${searchId}`, { replace: true });
                }
        };

        return (
                <div className="no-session-cont">
                        <div>Recent Races:</div>
                        <div className="session-search-div">
                                <input className="rr-ss-search" type="text" value={searchId} onChange={handleSearchChange} placeholder="Search Subsession ID" />
                                <button className="button-go-rr" onClick={handleSearchSubmit}>
                                        {" "}
                                        →{" "}
                                </button>
                        </div>
                        <div className="rr-or-div">Or, click a session below</div>
                        <div className="centering-div-rr">{renderSessions()}</div>
                </div>
        );
};

export default RecentSessionsList;
