import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import "../styles/WorldRecordsResultPage.css";

export const WorldRecordsResultPage = () => {
        const { car_id, track_id } = useParams();
        const location = useLocation();
        const [worldRecords, setWorldRecords] = useState(null);
        const [loading, setLoading] = useState(true);
        const [carName, setCarName] = useState(location.state?.carName || "");
        const [trackName, setTrackName] = useState(location.state?.trackName || "");
        const navigate = useNavigate();

        useEffect(() => {
                const fetchWorldRecords = async () => {
                        try {
                                const response = await fetch(`https://iracing6-backend.herokuapp.com/api/world-records/${car_id}/${track_id}`);
                                if (response.ok) {
                                        const data = await response.json();
                                        setWorldRecords(data);
                                } else {
                                        console.error("Failed to fetch world records");
                                }
                        } catch (error) {
                                console.error("Error fetching world records:", error);
                        } finally {
                                setLoading(false);
                        }
                };

                fetchWorldRecords();
        }, [car_id, track_id]);

        useEffect(() => {
                if (!carName) {
                        const fetchCarName = async () => {
                                try {
                                        const carResponse = await fetch(`https://iracing6-backend.herokuapp.com/api/car-basic-info/getName/${car_id}`);
                                        if (carResponse.ok) {
                                                const carData = await carResponse.json();
                                                setCarName(carData);
                                        } else {
                                                console.error("Failed to fetch car name");
                                        }
                                } catch (error) {
                                        console.error("Error fetching car name:", error);
                                }
                        };
                        fetchCarName();
                }
        }, [car_id]);

        useEffect(() => {
                if (!trackName) {
                        const fetchTrackName = async () => {
                                try {
                                        const trackResponse = await fetch(`https://iracing6-backend.herokuapp.com/api/track-basic-info/getName/${track_id}`);
                                        if (trackResponse.ok) {
                                                const trackData = await trackResponse.json();
                                                setTrackName(trackData);
                                        } else {
                                                console.error("Failed to fetch track name");
                                        }
                                } catch (error) {
                                        console.error("Error fetching track name:", error);
                                }
                        };
                        fetchTrackName();
                }
        }, [track_id]);

        // Convert milliseconds to "mm:ss.SSS" format
        const formatLapTime = (milliseconds) => {
                return Math.floor(milliseconds / 10000 / 60) + ":" + ("0" + Math.floor((milliseconds / 10000) % 60)).slice(-2) + ":" + ("000" + (milliseconds % 10000)).slice(-4);
        };

        if (loading) {
                return (
                        <div>
                                <div className="spinner"></div>
                        </div>
                );
        }

        // Show a message if there are no world records or if it's not an array
        if (!Array.isArray(worldRecords) || worldRecords.length === 0) {
                return (
                        <div className="no-wr-found-cont">
                                <div className="no-wr-found-div">No world records found for the selected car and track.</div>
                                <div className="go-to-search-wr-button1" onClick={() => navigate(`/worldrecords`)}>
                                        Go To Search
                                </div>
                        </div>
                );
        }

        const tableFieldRows = worldRecords.map((record, index) => {
                if (index < 3) return null;

                return (
                        <div key={index} className="wrrp-table-row-f">
                                <div className="wrrptpos">{index + 1}</div>
                                <div className="wrrptl">
                                        <h1>{formatLapTime(record.race_lap_time)}</h1>
                                        <h2 onClick={() => navigate(`/user/careerstats/${record.cust_id}`)}>{record.display_name}</h2>
                                </div>
                                <div className="wrrptrd">{record.race_date}</div>
                        </div>
                );
        });

        console.log(carName);

        return (
                <div className="world-records-result-page-cont">
                        <div className="wrrp-title-div">
                                <h2>{carName}</h2> at <h2>{trackName}</h2>
                        </div>
                        <div className="wrrp-table-cont-div">
                                {/* <div className="wrrp-table-top-bar-div">top bar</div> */}
                                <div className="wrrp-table-top-3-flex">
                                        <div className="wrrp-table-top3-pos-cont">
                                                <div className="wrrp-table-top3-pos1">#1</div>
                                                <div className="wrrp-table-top3-pos2">#2</div>
                                                <div className="wrrp-table-top3-pos3">#3</div>
                                        </div>
                                        <div className="wrrp-table-top3-cont">
                                                <div className="wrrp-table-top3-1">
                                                        <h2>{formatLapTime(worldRecords[0].race_lap_time)}</h2>
                                                        <h1 onClick={() => navigate(`/user/careerstats/${worldRecords[0].cust_id}`)}>{worldRecords[0].display_name}</h1>
                                                </div>
                                                <div className="wrrp-table-top3-2">
                                                        <h2>{formatLapTime(worldRecords[1].race_lap_time)}</h2>
                                                        <h1 onClick={() => navigate(`/user/careerstats/${worldRecords[1].cust_id}`)}>{worldRecords[1].display_name}</h1>
                                                </div>
                                                <div className="wrrp-table-top3-3">
                                                        <h2>{formatLapTime(worldRecords[2].race_lap_time)}</h2>
                                                        <h1>{worldRecords[2].display_name}</h1>
                                                </div>
                                        </div>
                                        <div className="wrrp-table-top3-right-cont">
                                                <div className="wrrp-table-top3-right-1">{worldRecords[0].race_date}</div>
                                                <div className="wrrp-table-top3-right-2">{worldRecords[1].race_date}</div>
                                                <div className="wrrp-table-top3-right-3">{worldRecords[2].race_date}</div>
                                        </div>
                                </div>
                        </div>

                        <div className="wrrp-table-field-row-cont">{tableFieldRows}</div>
                </div>
        );
};
